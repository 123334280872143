import { useField } from "formik"
import { Switch, SwitchProps } from "../raw/Switch"
import { cn } from "../../../lib"

export const FormikSwitch: React.FC<SwitchProps & { name: string }> = ({
  name,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name)

  return (
    <Switch
      className={cn("shrink", props.className)}
      {...props}
      onChange={(v) => setValue(v)}
      checked={field.value}
      error={meta.touched && meta.error}
    />
  )
}
