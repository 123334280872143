import { Upload, Button, UploadProps, UploadFile, notification } from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"
import { UploadOutlined } from "@ant-design/icons"
import { convertToWebp, uploadFileToS3 } from "../../../lib"
import React from "react"
import { format } from "date-fns"
import { usePrevious } from "react-use"
import slugify from "slugify"
export type MediaInputProps = FormControlProps &
  UploadProps & {
    value?: string
    onChange: (value: string | null) => void
    loading?: boolean
    name: string
    buttonLabel?: string
    dirName?: string
    filename?: string
    convertTo?: "webp"
  }

/**
 * A media input field
 * Uploads to DO on file chosen
 * If filename is provided, it will be
 */
export const MediaInput = React.memo(
  ({
    label,
    description,
    name,
    helpText,
    className,
    error,
    optional,
    loading,
    labelExtra,
    listType = "picture",
    multiple = false,
    buttonLabel = "Upload",
    dirName = "misc",
    filename,
    maxCount = 1,
    convertTo,
    ...props
  }: MediaInputProps) => {
    const ctxDisabled = useDisabled()
    const disabled = props.disabled || ctxDisabled || loading

    const previousValue = usePrevious(props.value)
    return (
      <FormControl
        label={label}
        name={name}
        disabled={disabled}
        description={description}
        error={error}
        helpText={helpText}
        optional={optional}
        labelExtra={labelExtra}
        className={className}
      >
        <Upload
          multiple={multiple}
          listType={listType}
          maxCount={maxCount}
          // TODO: handle multiple files?
          fileList={
            props.value
              ? [
                  {
                    uid: "",
                    url: props.value.replace(".cdn", ""),
                    name: props.value.split("/").pop() as string,
                  },
                ]
              : []
          }
          onChange={async ({ file: fileIn }) => {
            if (fileIn.status === "removed") {
              props.onChange(null)
              return
            }
            try {
              let file: any = fileIn.originFileObj
              if (convertTo === "webp") {
                file = await convertToWebp(file as unknown as File)
              }

              let finalName = `${format(new Date(), "yyyy-MM-dd-HH-mm-ss")}-${slugify(file.name.toLowerCase())}`
              if (filename) {
                const extension = file.name.split(".").pop()
                finalName = `${filename}.${extension}`
              }

              const url = await uploadFileToS3({
                file: file as any,
                directory: dirName,
                filename: finalName,
                contentType: file.type!,
              })
              props.onChange(url)
            } catch (err: any) {
              notification.open({
                message: "Error uploading file",
                description: err.message,
                type: "error",
              })
              props.onChange(previousValue || null)
            }
            return false
          }}
          disabled={disabled}
        >
          <Button icon={<UploadOutlined />} disabled={disabled}>
            {buttonLabel}
          </Button>
        </Upload>
      </FormControl>
    )
  },
)
