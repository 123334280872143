"use client"
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"
import { cn } from "../../../lib"

export interface SwitchProps
  extends AntdSwitchProps,
    Omit<FormControlProps, "children"> {}

export const Switch: React.FC<SwitchProps> = ({
  label,
  error,
  disabled,
  className,
  ...props
}) => {
  const ctxDisabled = useDisabled()
  return (
    <FormControl
      className={cn("flex flex-row gap-3 switch-form-control", className)}
      label={label}
      error={error}
    >
      <style jsx>{`
        .switch-form-control :global(.label) {
          flex-grow: 1;
        }
      `}</style>
      <AntdSwitch {...props} disabled={disabled || ctxDisabled} />
    </FormControl>
  )
}
