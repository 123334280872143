import { useField } from "formik"
import { TextArea, TextAreaProps } from "../raw/TextArea"

export const FormikTextarea: React.FC<TextAreaProps & { name: string }> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name)
  return <TextArea {...props} {...field} error={meta.touched && meta.error} />
}
