"use client"
import { Select as AntdSelect, SelectProps as AntdSelectProps } from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"

export interface SelectProps
  extends AntdSelectProps,
    Omit<FormControlProps, "children"> {}

export const Select: React.FC<SelectProps> = ({
  label,
  error,
  disabled,
  ...props
}) => {
  const ctxDisabled = useDisabled()
  return (
    <FormControl label={label} error={error}>
      <AntdSelect {...props} disabled={disabled || ctxDisabled} />
    </FormControl>
  )
}
