"use client"
import { useEffect } from "react"
import { Select, type SelectProps } from "../raw/Select"
import { useField } from "formik"

export default function FormikSelect(
  props: SelectProps & {
    name: string
  },
) {
  const [field, meta, { setValue, setTouched }] = useField(props.name)

  return (
    <Select
      {...field}
      onChange={(v) => {
        setValue(v)
        setTouched(true)
      }}
      // meta.touched so that it only show errors on the field we're on,
      // not all fields from the form
      error={meta.touched && meta.error}
      {...props}
    />
  )
}
