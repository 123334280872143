import { useField } from "formik"
import { Checkbox, CheckboxProps } from "../raw/Checkbox"

export const FormikCheckbox: React.FC<CheckboxProps & { name: string }> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name)
  return (
    <Checkbox
      {...props}
      {...field}
      checked={field.value}
      error={meta.touched && meta.error}
    />
  )
}
