export const formatAddress = (fullStr: string, strLen = 8, separator = "•") => {
  if (!fullStr) {
    return
  }
  if (fullStr.length <= strLen) {
    return fullStr
  }
  const charsToShow = strLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  const sepCharsToShow = Math.min(3, fullStr.length - strLen)
  const separatorDisplay = new Array(sepCharsToShow).fill(separator).join("")
  return `0x${fullStr.substring(
    2,
    frontChars + 2,
  )}${separatorDisplay}${fullStr.substring(fullStr.length - backChars)}`
}
