import { format, formatDistanceToNowStrict, parseISO } from "date-fns"

export const secondsToDuration = (
  input,
  optionValues = ["days", "hours", "minutes"],
) => {
  if (optionValues.includes("days") && input % (24 * 60 * 60) === 0) {
    return { value: input / (24 * 60 * 60), unit: "days" }
  } else if (optionValues.includes("hours") && input % (60 * 60) === 0) {
    return { value: input / (60 * 60), unit: "hours" }
  } else if (optionValues.includes("minutes") && input % 60 === 0) {
    return { value: input / 60, unit: "minutes" }
  }
  return { value: undefined, unit: undefined }
}

const dateFormats = {
  "long": "MMMM d, yyyy hh:mm",
  "short": "MMMM dd, yyyy",
  filename: "yyyy-MM-dd-hh-mm-ss",
  local: "PPPp", // local timezone, e.g. in nz March 8th, 2023 at 6:54:02 PM GMT+13
  localTime: "p",
  localDate: "PPP",
  dateInput: "yyyy-LL-dd",
  datetimeInput: "yyyy-LL-dd'T'HH:mm",
}
export const formatDate = (
  date,
  format_: string | keyof typeof dateFormats = "long",
) => {
  let date_ = date
  // this shouldn't really happen but it covers for when the data wasn't loaded yet...
  if (!date) {
    return undefined
  }
  if (!(date_ instanceof Date)) {
    date_ = parseISO(date_)
  }
  const result = format(date_, dateFormats[format_] || format_)

  // oh, this is crazy, but it's the easiest way to get the local timezone
  // without YYYY at the end via date-fns

  // maybe this should return "Thursday" if it's this thursday?
  if (format_ === "localDate") {
    return result.replace(/, \d{4}$/, "")
  }
  return result
}

export function formatDistance(
  timestamp?: number | null,
  { short } = { "short": false },
) {
  let distance = formatDistanceToNowStrict(timestamp || 0)
  distance = distance === "0 seconds" ? "just now" : `${distance} ago`

  if (short) {
    distance = distance
      .replace(/minutes?/, "min")
      .replace(/seconds?/, "sec")
      .replace(/ago/, "")
  }

  return distance
}
