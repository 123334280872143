"use client"
import { FormControl } from "../FormControl"
import { Input, Select } from "../raw"
import { useField } from "formik"
import { useEffect, useState } from "react"

const minute = 60
const hour = minute * 60
const day = hour * 24
const week = day * 7
const month = day * 31
const year = day * 365

const convertToSeconds = (value, unit) => {
  let seconds = value
  if (unit === "seconds") {
    // do nothing, already in seconds
  } else if (unit === "minutes") {
    seconds = value * minute
  } else if (unit === "hours") {
    seconds = value * hour
  } else if (unit === "days") {
    seconds = value * day
  } else if (unit === "weeks") {
    seconds = value * week
  } else if (unit === "months") {
    seconds = value * month
  } else if (unit === "years") {
    seconds = value * year
  }
  return seconds
}

const convertSecondsToUnit = (seconds, unit) => {
  let value = seconds
  if (unit === "seconds") {
    // do nothing, already in seconds
  } else if (unit === "minutes") {
    value = Math.round(seconds / minute)
  } else if (unit === "hours") {
    value = Math.round(seconds / hour)
  } else if (unit === "days") {
    value = Math.round(seconds / day)
  } else if (unit === "months") {
    value = Math.round(seconds / month)
  } else if (unit === "years") {
    value = Math.round(seconds / year)
  }
  return value
}

const findBestUnit = (secondsInput, optionValues) => {
  if (optionValues.includes("years") && secondsInput % year === 0) {
    return { value: Math.round(secondsInput / year), unit: "years" }
  } else if (optionValues.includes("months") && secondsInput % month === 0) {
    return { value: Math.round(secondsInput / month), unit: "months" }
  } else if (optionValues.includes("days") && secondsInput % day === 0) {
    return { value: Math.round(secondsInput / day), unit: "days" }
  } else if (optionValues.includes("hours") && secondsInput % hour === 0) {
    return { value: Math.round(secondsInput / hour), unit: "hours" }
  } else if (optionValues.includes("minutes") && secondsInput % minute === 0) {
    return { value: secondsInput / minute, unit: "minutes" }
  } else if (optionValues.includes("seconds")) {
    return { value: secondsInput, unit: "seconds" }
  }
  return { value: undefined, unit: undefined }
}

const storedUnitToBestUnit = (storedValue, baseUnit, optionValues) => {
  const seconds = convertToSeconds(storedValue, baseUnit)
  return findBestUnit(seconds, optionValues)
}

export const FormikDurationInput = ({
  name,
  options = [
    {
      label: "Seconds",
      value: "seconds",
    },
    {
      label: "Minutes",
      value: "minutes",
    },
    {
      label: "Hours",
      value: "hours",
    },
    {
      label: "Days",
      value: "days",
    },
    {
      label: "Months",
      value: "months",
    },
    {
      label: "Years",
      value: "years",
    },
  ],
  baseUnit = "seconds",
  allowNull = false,
  ...fieldProps
}) => {
  const [field, meta, helpers] = useField(name)
  const emptyValue =
    field.value === null || field.value === undefined || field.value === ""
  const { value: valueFromFormik, unit: unit_ } = emptyValue
    ? { value: null, unit: options[0]?.value }
    : storedUnitToBestUnit(
        field.value,
        baseUnit,
        options.map((option) => option.value),
      )
  const [unit, setUnit] = useState(unit_)
  const [localValue, setLocalValue] = useState(valueFromFormik)

  useEffect(() => {
    const seconds = convertToSeconds(localValue, unit)
    const baseValue = convertSecondsToUnit(seconds, baseUnit)
    if (
      (localValue !== null || allowNull) &&
      localValue !== undefined &&
      (localValue !== valueFromFormik || unit !== unit_)
    ) {
      const value = localValue === null && allowNull ? null : baseValue
      helpers.setValue(value)
    }
  }, [
    name,
    unit,
    localValue,
    baseUnit,
    valueFromFormik,
    allowNull,
    helpers,
    unit_,
  ])

  if (!unit) {
    return null
  }

  return (
    <FormControl {...meta} {...fieldProps} error={meta.touched && meta.error}>
      <div className="-mt-2 grid grid-cols-2 gap-4">
        <Input
          name={`${name}-value`}
          placeholder="1"
          type="number"
          min={fieldProps.min}
          max={fieldProps.max}
          value={localValue}
          onChange={(e) =>
            setLocalValue(
              e.target.value === "" ? null : parseInt(e.target.value, 10),
            )
          }
        />
        <Select
          name={`${name}-unit`}
          onChange={(value) => setUnit(value)}
          value={unit}
          options={options}
        />
      </div>
    </FormControl>
  )
}
