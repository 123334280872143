"use client"
import { useField } from "formik"
import { DatePicker, DatePickerProps } from "../raw/DatePicker"

export default function FormikDatePicker(
  props: DatePickerProps & {
    onChange: (value: Date) => void
  },
) {
  const [field, meta] = useField(props.name!)
  return (
    <DatePicker
      {...props}
      {...field}
      // meta.touched so that it only show errors on the field we're on,
      // not all fields from the form
      error={meta.touched && meta.error}
    />
  )
}
