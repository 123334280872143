/**
 * Format a number to an ordinal
 * 1 -> 1st
 * 2 -> 2nd
 * etc
 *
 */
export const formatOrdinalNumber = (n) => {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th"
}
