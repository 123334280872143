"use client"
import React from "react"
import { DateRange } from "../DateRange"
import { useField } from "formik"

export function FormikDateRange({ name, ...props }) {
  const [field, meta, helpers] = useField(name)

  return (
    <DateRange
      {...field}
      {...props}
      onChange={(value) => {
        helpers.setValue(value)
      }}
      error={meta.touched && meta.error ? "border-red-500" : ""}
    />
  )
}
