"use client"
import React from "react"
import { ColorPicker as AntColorPicker } from "antd"
import type { ColorPickerProps as AntColorPickerProps } from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"
import { cn } from "../../../lib/cn"

export type ColorPickerProps = AntColorPickerProps &
  FormControlProps & {
    loading?: boolean
  }

export function ColorPicker({
  label,
  description,
  name,
  helpText,
  className,
  error,
  optional,
  loading,
  labelExtra,
  ...props
}: ColorPickerProps) {
  const ctxDisabled = useDisabled()
  const disabled = props.disabled || ctxDisabled || loading

  return (
    <FormControl
      label={label}
      name={name}
      disabled={disabled}
      description={description}
      error={error}
      helpText={helpText}
      optional={optional}
      labelExtra={labelExtra}
      className={className}
    >
      <AntColorPicker
        showText
        id={name}
        {...props}
        disabled={disabled}
        data-1p-ignore
        data-lpignore="true"
      />
    </FormControl>
  )
}
