"use client"
import type { ColorPickerProps } from "../raw/ColorPicker"
import { ColorPicker } from "../raw/ColorPicker"
import { useField, useFormikContext } from "formik"

export function FormikColorPicker({ ...props }: ColorPickerProps) {
  const [field, meta, { setValue }] = useField(props.name!)

  // for some reason, formik.errors has a `token[0].name` key instead of a structured object
  // touched, however, remains as an object 🤷
  const { errors } = useFormikContext()
  const error = meta.error || errors[props.name!]
  return (
    <ColorPicker
      {...field}
      onChangeComplete={(value) => setValue(value)}
      // meta.touched so that it only show errors on the field we're on,
      // not all fields from the form
      error={meta.touched && error}
      {...props}
    />
  )
}
