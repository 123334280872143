import { Formik, FormikConfig } from "formik"
import { SetRequired } from "type-fest"
import { SchemaContext } from "./SchemaContext"
import { YupInfer } from "../yup"

/**
 * This is a wrapper around Formik that casts the values to the correct types
 * It adds the schema description to be available as a context
 * It will cast the values to the correct types before passing them on to the onSubmit handler
 *
 * It defines the behaviour we generally want for forms in the app
 */
export function SmartFormik<T extends {}>({
  validationSchema,
  onSubmit,
  initialValues,
  enableReinitialize = true,
  completeResource = {},
  schemaContext = {},
  ...props
}: SetRequired<FormikConfig<T>, "validationSchema"> & {
  completeResource?: any
  schemaContext?: {
    isFinal?: boolean
    unit?: "wei" | "eth"
  }
}) {
  return (
    <SchemaContext.Provider
      value={{
        schema: validationSchema,
        completeResource,
      }}
    >
      {/* @ts-ignore why is this failing to ts?? */}
      <Formik
        {...props}
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        onSubmit={async (values: T, helpers) => {
          const cast = validationSchema.cast(values, {
            // stripUnknown: true,
          }) as T
          return onSubmit(cast, helpers)
        }}
        validate={async (values: YupInfer<typeof validationSchema>) => {
          try {
            await validationSchema.validate(
              {
                ...completeResource,
                ...values,
              },
              {
                abortEarly: false,
                context: {
                  // we pass back the values so that we can use them in the yup validation.
                  ...completeResource,
                  ...values,
                  ...schemaContext,
                },
              },
            )
          } catch (e: any) {
            const errors = {}
            try {
              e.inner.forEach((error) => {
                errors[error.path] = error.message
              })
              // leaving this in until this component is solid

              // console.log({ values, errors })
              return errors
            } catch (err) {
              console.log(values)
              console.log(err)
              console.log("error", JSON.stringify(e))
              console.log(JSON.stringify(err))
            }
          }
          return {}
        }}
      />
    </SchemaContext.Provider>
  )
}

SmartFormik.displayName = "SmartFormik"
