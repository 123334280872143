import { MediaInput, type MediaInputProps } from "../raw/MediaInput"
import { useField, useFormikContext } from "formik"

/**
 * FormikMediaField
 * Only supports single file uploads
 *
 * @param name - name of the field
 * @param label - label of the field
 *
 * TODO: show video as background-image
 */
export const FormikMediaInput = (props: MediaInputProps) => {
  const [{ value }, meta, { setValue }] = useField(props.name)

  // for some reason, formik.errors has a `token[0].name` key instead of a structured object
  // touched, however, remains as an object 🤷
  const { errors } = useFormikContext()
  const error = meta.touched && (meta.error || errors[props.name!])
  return (
    <MediaInput {...props} value={value} error={error} onChange={setValue} />
  )
}
