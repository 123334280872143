"use client"
import React from "react"
import { Input as AntInput } from "antd"
import type { InputProps as AntInputProps } from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"
import { cn } from "../../../lib/cn"

export type InputProps = AntInputProps &
  FormControlProps & {
    loading?: boolean
  }

export function Input({
  label,
  description,
  name,
  helpText,
  className,
  error,
  optional,
  loading,
  labelExtra,
  ...props
}: InputProps) {
  const ctxDisabled = useDisabled()
  const disabled = props.disabled || ctxDisabled || loading

  return (
    <FormControl
      label={label}
      name={name}
      disabled={disabled}
      description={description}
      error={error}
      helpText={helpText}
      optional={optional}
      labelExtra={labelExtra}
      className={className}
    >
      <AntInput
        id={name}
        name={name}
        {...props}
        disabled={disabled}
        status={error ? "error" : ""}
        className={cn(props.classNames)}
        data-1p-ignore
        data-lpignore="true"
      />
    </FormControl>
  )
}
