/**
 * Converts a file to a WebP file
 * @param file The file to convert
 * @returns A promise that resolves to a WebP file
 */
export const convertToWebp = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        try {
          const canvas = document.createElement("canvas")
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext("2d")
          if (!ctx) {
            reject(new Error("Failed to get canvas context"))
            return
          }
          ctx.drawImage(img, 0, 0)
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error("Failed to convert image to WebP"))
              return
            }
            const webpFile = new File(
              [blob],
              file.name.replace(/\.[^/.]+$/, ".webp"),
              {
                type: "image/webp",
              },
            )
            resolve(webpFile)
          }, "image/webp")
        } catch (error: any) {
          reject(error.message || "Failed to convert image to WebP")
        }
      }
      img.onerror = () => {
        reject(new Error("Failed to load image"))
      }
      img.src = event.target?.result as string
    }
    reader.onerror = () => {
      reject(new Error("Failed to read file"))
    }
    reader.readAsDataURL(file)
  })
}
