"use client"

import { FormikCheckbox } from "./FormikCheckbox"
import { FormikInput } from "./FormikInput"
import { FormikTextarea } from "./FormikTextarea"
import get from "lodash/get"
import { useContext } from "react"
import FormikDatePicker from "./FormikDatePicker"
import { FormikDurationInput } from "./FormikDurationInput"
import { FormikMediaInput } from "./FormikMediaInput"

import { FormikSwitch } from "./FormikSwitch"
import { SchemaContext } from "./SchemaContext"
import FormikSelect from "./FormikSelect"
import {
  CheckboxProps,
  ColorPickerProps,
  InputProps,
  SelectProps,
  SwitchProps,
} from "../raw"
import { MediaInputProps } from "../raw/MediaInput"
import { FormikDateRange } from "./FormikDateRange"
import { FormikColorPicker } from "./FormikColorPicker"

export const useFieldFromSchema = ({ name }) => {
  const { schema, completeResource } = useContext(SchemaContext)
  let finalName = name
  // nested.bird => nested.fields.bird to match the schema description
  finalName = finalName.split(".").join(".fields.")
  // multiple.3. => multiple.innerType
  finalName = finalName.replace(/(\[\d+\])/g, ".innerType")
  const field = get(
    schema.describe({ value: completeResource }).fields,
    finalName,
  )

  if (!field) {
    console.log(
      "Can't find field",
      name,
      `(${finalName})`,
      "in",
      schema.describe().fields,
    )
  }
  return field
}

type Widget =
  | "text"
  | "textarea"
  | "select"
  | "media"
  | "date"
  | "number"
  | "checkbox"
  | "duration"
  | "color"

export const SmartField = ({
  name,
  ...props
}: { name: string; widget?: Widget } & (
  | InputProps
  | MediaInputProps
  | CheckboxProps
  | SwitchProps
  | SelectProps
  | ColorPickerProps
)) => {
  const { completeResource } = useContext(SchemaContext)
  const field = useFieldFromSchema({ name })

  if (!field) {
    // still logging errors for now for easy debugging
    return (
      <div className="flex items-center gap-3 text-red-500">
        <span className="text-3xl">!</span>
        Error rendering field {name}
      </div>
    )
  }
  const {
    type,
    label: givenLabel,
    meta = {},
    tests,
  } = field as unknown as {
    type: string
    label: string
    meta: any
    tests: any[]
  }
  const label = givenLabel || name.split(".").pop()
  const widget = props.widget || meta.widget
  let min = tests.find((t) => t.name === "min")?.params.min
  let max = tests.find((t) => t.name === "max")?.params.max
  const labelExtra = meta?.labelExtra || props?.labelExtra

  // if (admin) {
  //   return null
  // }
  const fieldProps = {
    // TODO: don't pass meta, as it passes arbitrary props to the component
    // while we might want to have them for other purposes(eg. unit: "eth")
    // ...meta,
    ...meta.props,
    name: name,
    label: label,
    // override with anything from up there
    ...props,
    labelExtra,
  }
  if (widget === "select") {
    return (
      <FormikSelect
        {...fieldProps}
        options={fieldProps.options}
        optionsFrom={fieldProps.optionsFrom}
      />
    )
  }

  if (widget === "dateRange") {
    return <FormikDateRange name={name} {...props} />
  }
  if (widget === "color") {
    // @ts-ignore cause value would always be ColorValue in this case
    return <FormikColorPicker name={name} {...props} />
  }

  if (widget === "media") {
    return (
      <FormikMediaInput
        provider={meta.provider || "uploadcare"}
        parentId={completeResource?.id}
        {...fieldProps}
      />
    )
  }

  if (type === "date") {
    return <FormikDatePicker {...fieldProps} min={min} max={max} />
  }
  if (type === "number" || meta.unit === "eth") {
    // const icon = meta.unit === "eth" ? ETHIcon : undefined
    const icon = meta.unit === "eth" ? "eth" : undefined

    if (meta.unit === "eth") {
      min = meta.min
      max = meta.max
    }
    if (widget === "duration") {
      return <FormikDurationInput {...fieldProps} />
    }
    return (
      <FormikInput
        type={"number"}
        min={min}
        max={max}
        icon={icon}
        {...fieldProps}
        labelExtra={labelExtra}
      />
    )
  }
  if (type === "string") {
    if (widget === "textarea") {
      return <FormikTextarea {...fieldProps} />
    }

    // we default to text, but want to catch unknown widgets
    if (!widget || widget === "text") {
      return <FormikInput {...fieldProps} />
    }
  }
  if (type === "boolean") {
    if (widget === "switch") {
      return <FormikSwitch {...fieldProps} />
    }
    return <FormikCheckbox {...fieldProps} />
  }

  console.error("Unkown widget", widget)
  return (
    <div className="text-negative">
      Unknown widget for {type}_{widget}
    </div>
  )
}

SmartField.displayName = "SmartField"
