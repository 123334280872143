"use client"
import { Button as AntdButton, ConfigProvider } from "antd"
import Link from "next/link"
import { useDisabled } from "./form"
import { cn } from "../lib"
import { useMemo } from "react"
export type ButtonProps = Omit<
  React.ComponentProps<typeof AntdButton>,
  "color"
> & {
  as?: string
  scroll?: boolean
  color?: keyof typeof themes
}

const themes = {
  white: {
    colorText: "#3F49CC",
    colorBgContainer: "#FFFFFF",
  },
  brand: {
    colorText: "#FFFFFF",
    defaultHoverColor: "#FFFFFF",
    colorBgContainer: "#1d29c2",
  },
  brandDark: {
    colorText: "#FFFFFF",
    colorBgContainer: "#1d29c2",
  },
  brandLight: {
    colorText: "#FFFFFF",
    colorBgContainer: "#5F68D7",
  },
  yellow: {
    colorText: "#3F49CC",
    colorBgContainer: "#FCEE21",
  },
}

export default function Button({ color = "white", as, ...props }: ButtonProps) {
  const disabled_ = useDisabled()
  const disabled =
    props.disabled === undefined ? disabled_ || props.disabled : props.disabled

  const theme = useMemo(
    () => ({
      components: {
        Button: {
          colorBorder: "transparent",
          ...themes[color],
        },
      },
    }),
    [color],
  )

  if (props.href && !props.href.startsWith("http")) {
    const { href, scroll = true, ...rest } = props
    return (
      <ConfigProvider theme={theme}>
        <Link href={href} passHref as={as} scroll={scroll || undefined}>
          <AntdButton
            {...rest}
            type="default"
            disabled={disabled}
            className={cn("hover:!no-underline", rest.className)}
          />
        </Link>
      </ConfigProvider>
    )
  }
  return (
    <ConfigProvider theme={theme}>
      <AntdButton
        {...props}
        type="default"
        disabled={disabled}
        className={cn("hover:!no-underline", props.className)}
      />
    </ConfigProvider>
  )
}
