"use client"
import React from "react"
import { createContext, useContext } from "react"

export const DisabledContext = createContext(false)

export const DisabledProvider = ({
  value,
  bypassParent = false,
  ...props
}: {
  value: boolean
  bypassParent?: boolean
  children: React.ReactNode | React.ReactNode[]
}) => {
  const parentDisabled = useDisabled()
  return (
    <DisabledContext.Provider
      value={bypassParent ? value : parentDisabled || value}
      {...props}
    />
  )
}

DisabledContext.displayName = "DisabledContext"

export const useDisabled = () => useContext(DisabledContext)
