"use client"
import { createContext } from "react"

interface SchemaContext {
  // TODO: Yup.ObjectSchema
  schema: any
  completeResource: any
}

/**
 * Make the schema available to children, to allow fields to render from the schema itself
 */
export const SchemaContext = createContext<SchemaContext>({} as SchemaContext)
