"use client"
import type { InputProps } from "../raw/Input"
import { Input } from "../raw/Input"
import { useField, useFormikContext } from "formik"

export function FormikInput({ ...props }: InputProps) {
  const [field, meta] = useField(props.name!)

  // for some reason, formik.errors has a `token[0].name` key instead of a structured object
  // touched, however, remains as an object 🤷
  const { errors } = useFormikContext()
  const error = meta.error || errors[props.name!]
  return (
    <Input
      {...field}
      // meta.touched so that it only show errors on the field we're on,
      // not all fields from the form
      error={meta.touched && error}
      {...props}
    />
  )
}
