"use client"
import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps,
} from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"
import { cn } from "../../../lib"

export interface CheckboxProps
  extends AntdCheckboxProps,
    Omit<FormControlProps, "children"> {}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  error,
  labelExtra: _,
  disabled,
  className,
  ...props
}) => {
  const ctxDisabled = useDisabled()
  return (
    <FormControl
      className={cn(
        "flex-row-reverse justify-end items-center gap-1",
        className,
      )}
      label={label}
      error={error}
    >
      <AntdCheckbox {...props} disabled={disabled || ctxDisabled} />
    </FormControl>
  )
}
