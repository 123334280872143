"use client"
import React from "react"
import { DatePicker } from "antd"
import { FormControl, FormControlProps } from "./FormControl"
import { useDisabled } from "./useDisabled"
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns"

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig)

export type DateRangeProps = React.ComponentProps<
  typeof DatePicker.RangePicker
> &
  FormControlProps & {
    loading?: boolean
  }

const dateForHours = new Date()
dateForHours.setHours(12)
dateForHours.setMinutes(0)
export function DateRange({
  name,
  label,
  description,
  helpText,
  className,
  error,
  optional,
  loading,
  labelExtra,
  showTime = true,
  ...props
}: DateRangeProps) {
  const ctxDisabled = useDisabled()
  const disabled = props.disabled || ctxDisabled || loading
  return (
    <FormControl
      label={label}
      name={name}
      disabled={disabled}
      description={description}
      error={error}
      helpText={helpText}
      optional={optional}
      labelExtra={labelExtra}
      className={className}
    >
      {/*
       * i spent an hour trying to figure out how to make this accept controlled values
       * using the default dayjs lib, and eventually found that you can use date-fns
       * which we already have in our stack.
       * https://ant.design/docs/react/use-custom-date-library#datepicker
       */}
      {/* @ts-ignore  */}
      <MyDatePicker.RangePicker
        name={name}
        showTime={{
          format: "HH:mm",
          defaultValue: [dateForHours, dateForHours],
        }}
        {...props}
        // casting as unbounded array vs 2 length array expected

        status={Boolean(error) ? "error" : undefined}
      />
    </FormControl>
  )
}
