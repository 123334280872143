"use client"

import { DisabledProvider, useDisabled } from "./useDisabled"
import { cn } from "../../lib/cn"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { AnimatePresence, motion } from "framer-motion"
import ErrorBoundary from "../../utils/client/ErrorBoundary"
import { Tooltip } from "antd"

export const ErrorOrDescription = ({
  error,
  description,
  disabled,
}: {
  error?: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
}) => (
  <ErrorBoundary fallback={() => "There was an error"}>
    <AnimatePresence initial={false}>
      {(error || description) && (
        <motion.div
          initial={{
            opacity: 0,
            y: -4,
            maxHeight: 0,
            paddingTop: "0rem",
          }}
          animate={{
            opacity: 1,
            y: 0,
            maxHeight: "5rem",
            paddingTop: "0.1rem",
          }}
          exit={{
            opacity: 0,
            y: -4,
            maxHeight: 0,
            paddingTop: "0rem",
          }}
          className={cn(
            "overflow-hidden",
            "px-3 text-right text-xs",
            "text-muted",
            "error-or-description",
            disabled && "text-gray-70",
            error && "field-error text-red-500",
          )}
        >
          {error || description}
        </motion.div>
      )}
    </AnimatePresence>
  </ErrorBoundary>
)

export interface FormControlProps {
  label?: React.ReactNode | string
  name?: string
  helpText?: React.ReactNode | string
  disabled?: boolean
  description?: React.ReactNode
  error?: React.ReactNode
  className?: string
  optional?: boolean
  labelExtra?: React.ReactNode | string
  // it can be useful to wrap in smth else than a label if we have 2 inputs
  // within the same form control, as it gives more control over focus
  WrapperComponent?: "label" | "div"
  id?: string
}

export function FormControl({
  label,
  name,
  helpText,
  disabled = false,
  description,
  error,
  children,
  className,
  optional,
  labelExtra,
  WrapperComponent = "label",
  id,
}: FormControlProps & { children: React.ReactNode | React.ReactNode[] }) {
  const ctxDisabled = useDisabled() || disabled
  return (
    <DisabledProvider value={ctxDisabled}>
      <WrapperComponent
        id={id}
        htmlFor={name}
        className={cn(
          "flex grow flex-col gap-1 justify-start",
          "my-2",
          "text-gray-95",
          "group",
          {
            "cursor-not-allowed text-gray-40": disabled,
          },
          className,
        )}
      >
        {label && (
          <div
            className={cn(
              "label",
              "flex items-center justify-between gap-2 transition ease-in-out",
              "px-3",
              "text-xs text-gray-600 group-focus-within:text-gray-800",
            )}
          >
            <div className="flex gap-2">
              <div>{label}</div>
              {optional && (
                <span className="font-normal text-muted">Optional</span>
              )}
              {helpText && (
                <Tooltip title={helpText} key={name}>
                  <QuestionCircleOutlined className={cn("h-4 w-4")} />
                </Tooltip>
              )}
            </div>
            {labelExtra !== undefined && (
              <span
                className={cn(
                  "font-normal text-muted",
                  typeof labelExtra === "number" &&
                    labelExtra < 0 &&
                    "text-negative",
                )}
              >
                {labelExtra}
              </span>
            )}
          </div>
        )}
        {children}
        <ErrorOrDescription
          error={error}
          description={description}
          disabled={disabled}
        />
      </WrapperComponent>
    </DisabledProvider>
  )
}
