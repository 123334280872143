import axios from "axios"

/**
 * we omit directory in case we are saving the key returned by an S3
 */
export const cdnUrl = (key) =>
  `https://${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}.${[process.env.NEXT_PUBLIC_S3_ENDPOINT, process.env.NEXT_PUBLIC_S3_DIRECTORY, key].filter(Boolean).join("/")}`

export async function uploadFileToS3({
  file,
  directory,
  filename,
  contentType,
}: {
  file: File
  directory: string
  filename: string
  contentType: string
}): Promise<string> {
  // Get the presigned URL from the API
  const { data } = await axios.put("/api/get-signed-url", {
    directory: [process.env.NEXT_PUBLIC_S3_DIRECTORY, directory].join("/"),
    filename,
    contentType,
  })
  const { presignedUrl, key } = data
  if (file === undefined) {
    throw new Error("File is undefined")
  }
  // Upload the file directly to S3 using the presigned URL
  const res = await axios.put(presignedUrl, file, {
    headers: {
      "x-amz-acl": "public-read",
    },
  })
  console.log("Uploaded file to S3:", res)

  // Don't use `cdnUrl` as it inpends the directory, which is already included in `key`
  return `https://${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}.${process.env.NEXT_PUBLIC_S3_ENDPOINT}/${key}`
}
