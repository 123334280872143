import { ExclamationCircleOutlined } from "@ant-design/icons"
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs"
import cn from "clsx"
import Button from "../../ui/Button"

const fallbackDefault = (className?: string) => (
  <div
    className={cn(
      "flex flex-col items-center gap-5 text-center text-primary py-4",
      className,
    )}
  >
    <ExclamationCircleOutlined className="h-10 w-10" />
    <div className="text-xl font-bold">Sorry, something went wrong.</div>
    <div className="max-w-prose">
      Hey there, it looks like something went wrong on our end. Don't worry,
      we're on it and working to fix the issue as soon as possible. Thanks for
      your patience!
    </div>
    <div className="flex gap-4">
      <Button className="mt-4" type="primary" href="/">
        Home
      </Button>
      <Button className="mt-4" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </div>
  </div>
)

export const withErrorBoundary =
  (Component, { fallback = fallbackDefault(), ...rest } = {}) =>
  (props) => (
    <SentryErrorBoundary {...{ fallback, ...rest }}>
      <Component {...props} />
    </SentryErrorBoundary>
  )

const ErrorBoundary = ({
  className = "",
  children,
  ...rest
}: {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
} & Record<string, any>) => (
  <SentryErrorBoundary {...{ fallback: fallbackDefault(className), ...rest }}>
    {children}
  </SentryErrorBoundary>
)

export default ErrorBoundary
