"use client"
import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntdDatePickerProps,
} from "antd"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"

export interface DatePickerProps
  extends AntdDatePickerProps,
    Omit<FormControlProps, "children"> {}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  error,
  disabled,
  ...props
}) => {
  const ctxDisabled = useDisabled()
  return (
    <FormControl label={label} error={error}>
      <AntdDatePicker {...props} disabled={disabled || ctxDisabled} />
    </FormControl>
  )
}
