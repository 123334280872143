"use client"
import { Input } from "antd"
import { TextAreaProps as AntdTextAreaProps } from "antd/lib/input"
import { FormControl, FormControlProps } from "../FormControl"
import { useDisabled } from "../useDisabled"

const { TextArea: AntdTextArea } = Input

export interface TextAreaProps
  extends AntdTextAreaProps,
    Omit<FormControlProps, "children"> {}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  disabled,
  className,
  labelExtra,
  description,
  ...props
}) => {
  const ctxDisabled = useDisabled()
  return (
    <FormControl
      label={label}
      error={error}
      labelExtra={labelExtra}
      className={className}
      description={description}
    >
      <AntdTextArea {...props} disabled={disabled || ctxDisabled} />
    </FormControl>
  )
}
